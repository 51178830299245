<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>数据排行榜</a-breadcrumb-item>
            <a-breadcrumb-item>签约金额排行</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>


                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>上课校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>

                    
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="dashboard bk-group">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <statistics :count='counts' :col="4"/>
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <culeCount :loading='loadingC' title="签约金额（元）"  :leads_data ='contractStatisData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="校区签约金额排行" :rank_data="studio_sale_statis" :loading="loadingD" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="顾问签约金额排行" :rank_data="course_consultant_statis" :loading="loadingD" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="渠道签约金额排行" :rank_data="channel_sale_count" :loading="loadingD" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="市场签约金额排行" :rank_data="mkt_sale_count" :loading="loadingD" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loadingCS' :item ='channelStatis[0]' title="渠道签约比例（单）" subtext='总数（单）' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loadingCS' :item ='channelStatis[1]' title="渠道金额比例（元）" subtext='总金额（元）' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loading' :item ='ringDataL' title="签约课程比例（单）" subtext='总数（单）' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loading' :item ='ringDataR' title="签约金额比例（元）" subtext='总金额（元）' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loadingD' :item ='studio_sale_statis' title="校区签约金额比例（元）" subtext='总金额（元）' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loadingD' :item ='course_consultant_statis' title="顾问签约金额比例（元）" subtext='总金额（元）' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="课程科目签约数量" :rank_data="course_sale_count" :loading="loadingD" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="课程科目签约金额" :rank_data="course_sale_statis" :loading="loadingD" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <refundStatis :loading='loadingRS'  :item ='refundStatisData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <refundContractStatis :loading='loadingRC'  :item ='refundContractStatisData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import statistics from './statistics'


import sale from '../course/sale'
import culeCount from './cule_count'
import refundStatis from '../course/refundStatis'
import refundContractStatis from '../course/refundContractStatis'

export default {
  name: 'xqypb',

  components: {
    statistics,
    sale,
    culeCount,
    refundStatis,
    refundContractStatis
  },

  data() {
    return {
      loading:false,
      loadingD:false,
      loadingS:false,
      loadingC:false,
      loadingRS:false,
      loadingRC:false,
      loadingCS:false,
      start_date:'',
      end_date:'',
      studios:[],
      counts:[],
      course_consultant_statis:{},
      course_sale_count:{},
      channel_sale_count:{},
      course_sale_statis:{},
      studio_sale_statis:{},
      channelStatis:[{},{}],
      mkt_sale_count:{},
      ringDataL:[],
      ringDataR:[],
      saleData:[],
      contractStatisData:[],
      refundStatisData:[],
      refundContractStatisData:[],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        studio_id:'',
        filter_type:'day'
      },
    }
  },
  mixins: [ranges],
  created() {
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStudio()
  },
  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.getCount(obj)
      this.getRing(obj)
      this.getSale(obj)
      this.getContractStatis(obj)
      this.getRefundStatis(obj)
      this.getRefundContractStatis(obj)
      this.getChannelStatis(obj)
      this.getCampusRank(obj)
    },
    async getCampusRank(obj){
      this.loadingD = true

      let res = await this.$store.dispatch('analysisCampusRankAction', obj);

      //校区销售金额
      this.studio_sale_statis= res.data.studio_sale_statis;

      //课程顾问签约金额
      this.course_consultant_statis= res.data.course_consultant_statis;

      //科目签约数量
      this.course_sale_count= res.data.course_sale_count;

      //渠道签约金额 
      this.channel_sale_count = res.data.channel_statis;

      //科目签约金额
      this.course_sale_statis= res.data.course_sale_statis;

      this.mkt_sale_count = res.data.mkt_statis;

      this.loadingD = false
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisCampusCountAction', obj)
      this.counts = res.data
    },
    async getRing(obj){
      this.loading = true
      let res = await this.$store.dispatch('analysisSummateAction', obj)
      this.ringDataL = res.data[0]
      this.ringDataR = res.data[1]
      this.loading = false
    },
    async getSale(obj){
      this.loadingS = true
      let resS = await this.$store.dispatch('analysisSaleStatisAction', obj)
      this.saleData = resS.data
      this.loadingS = false
    },
    async getContractStatis(obj){
      this.loadingC = true
      let resC = await this.$store.dispatch('analysisContractStatisAction', obj)
      this.contractStatisData = resC.data
      this.loadingC = false
    },
    async getRefundStatis(obj){
      this.loadingRS = true
      let resRS = await this.$store.dispatch('analysisRefundStatisAction', obj)
      this.refundStatisData = resRS.data
      this.loadingRS = false
    },
    async getRefundContractStatis(obj){
      this.loadingRC = true
      let resRC = await this.$store.dispatch('analysisRefundContractStatisAction', obj)
      this.refundContractStatisData = resRC.data
      this.loadingRC = false
    },
    async getChannelStatis(obj){
      this.loadingCS = true
      let res = await this.$store.dispatch('analysisChannelStatisAction', obj)
      this.channelStatis = res.data
      this.loadingCS = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen();
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    }
  }
}
</script>